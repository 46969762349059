export function required(value) {
    return value.trim()
}

export function password(value) {
    return /((?=.*\d)(?=.*[a-zA-Z]).{7,20})/.test(value)
}

export function mobile(value) {
    return /^(1[3-9])\d{9}$/.test(value)
}

export function internationalMobile(value) {
    return /^(1[3-9])\d{9}$|^(\+|00)\d{8,15}$/.test(value)
}

export function telephone(value) {
    return /^(\d{3,4}-?)?\d{7,8}$/.test(value)
}

export function email(value) {
    return /(^\s*[a-zA-Z0-9_%+#&'*/=^`{|}~-](?:\.?[a-zA-Z0-9_%+#&'*/=^`{|}~-])*@(?:[a-zA-Z0-9_](?:(?:\.?|-*)[a-zA-Z0-9_])*\.[a-zA-Z]{2,9}|\[(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)])\s*$)|(^(1[3-9])\d{9}$)/.test(value)
}

export function wechat(value) {
    // 微信的长度限6~20位
    return /^\S{6,20}$/.test(value)
}

export function max(max, value) {
    return value.length <= max
}

export function min(min, value) {
    return value.length >= min
}

export function nick(value) {
    return /^[A-Za-z0-9_]{4,30}$/.test(value)
}

export function positiveInteger(value) {
    return /^[1-9]\d*$/.test(value)
}

export function html(htmlStr) {
    let reg = /<([A-Za-z]+|(\/+)|(\/+.\/*)+|([A-Za-z]+\/+))>/g;
    return reg.test(htmlStr);
}