var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-create" },
    [
      _c(
        "div",
        { staticClass: "single-job-create" },
        [
          _vm._m(0),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "form",
              staticClass: "single-job-create-form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c("h4", { staticClass: "single-job-create-form-title" }, [
                _vm._v("职位"),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "职位名称", required: true, prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "col-8",
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "职位类别",
                    required: true,
                    prop: "category",
                  },
                },
                [
                  _c("el-cascader", {
                    staticClass: "col-8",
                    attrs: {
                      placeholder: "请选择职位类别",
                      options: _vm.jobCategories.slice(1),
                      "show-all-levels": false,
                      props: {
                        expandTrigger: "hover",
                        value: "id",
                        label: "name",
                        children: "subJobCategories",
                        emitPath: false,
                      },
                    },
                    on: { change: _vm.handleCategoryChange },
                    model: {
                      value: _vm.form.category,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "category", $$v)
                      },
                      expression: "form.category",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属公司", prop: "customerName" } },
                [
                  _c("customer-select", {
                    ref: "customerSelect",
                    staticClass: "col-8",
                    attrs: { placeholder: "请选择所属公司" },
                    on: { customerSelected: _vm.handleCustomerNodeClick },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "add-customer-btn",
                      on: { click: _vm.handleCustomerCreate },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                      _c("em", [
                        _vm._v(
                          _vm._s(_vm.isCustomer ? "添加客户" : "申请创建客户")
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属BG/BU/部门",
                    prop: "customerDepartmentId",
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "col-8" },
                    [
                      _c("customer-department-select", {
                        ref: "departmentSelect",
                        attrs: {
                          isSearchOnlyLeaf: true,
                          isOnlyLeaf: true,
                          customerId: _vm.form.targetCompany,
                          isDisabled: !_vm.form.targetCompany,
                          departmentId: _vm.form.customerDepartmentId,
                          placeholder: "请输入部门关键字，至少两位",
                        },
                        on: { "update-department": _vm.updateDepartmentId },
                      }),
                    ],
                    1
                  ),
                  _vm.form.targetCompany
                    ? _c(
                        "span",
                        {
                          staticClass: "add-department-btn",
                          on: {
                            click: function ($event) {
                              return _vm.dispatch("App", "createDepartment", {
                                customerId: _vm.form.targetCompany,
                                customerName: _vm.form.customerName,
                              })
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                          }),
                          _c("em", [_vm._v("添加BG/BU/部门")]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              !_vm.form.IsPublic
                ? _c(
                    "el-form-item",
                    { attrs: { label: "所属项目", prop: "projectId" } },
                    [
                      _c("project-select", {
                        ref: "projectSelect",
                        staticClass: "col-8",
                        attrs: {
                          placeholder: "请输入所属项目",
                          isDisabled: !(
                            _vm.form.targetCompany &&
                            _vm.form.customerDepartmentId
                          ),
                        },
                        on: { projectSelected: _vm.changeProject },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "add-customer-btn",
                          on: { click: _vm.handleProjectCreate },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                          }),
                          _c("em", [_vm._v("添加项目")]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "工作地区",
                    required: true,
                    prop: "locations",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "col-8",
                    attrs: {
                      readOnly: "true",
                      placeholder: "请选择，最多选择5项",
                      value: _vm.locationName,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.showCitySelect.apply(null, arguments)
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "hot-city" },
                    _vm._l(_vm.hotCityMap, function (city, key) {
                      return _c(
                        "span",
                        {
                          key: key,
                          on: {
                            click: function ($event) {
                              return _vm.selectHotCity(city.label, city.value)
                            },
                          },
                        },
                        [_vm._v(_vm._s(city.label))]
                      )
                    }),
                    0
                  ),
                  _c("select-city-dialog", {
                    ref: "selectCityDialog",
                    on: { "city-selected": _vm.handleCitySelect },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址" } },
                [
                  _c("el-input", {
                    staticClass: "col-8",
                    attrs: { maxLength: "50" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  class: _vm.jobLevelError ? "is-error" : "",
                  attrs: { label: "职级" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "job-level-wrap",
                      attrs: { id: "jobLevelList" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.jobLevel,
                            expression: "jobLevel",
                          },
                        ],
                        staticClass: "job-level-ipt",
                        class: _vm.jobLevelError ? "el-input__inner" : "",
                        attrs: {
                          placeholder:
                            "请选填职级，多个职级请用“/”隔开，如：P6/P7/P8",
                        },
                        domProps: { value: _vm.jobLevel },
                        on: {
                          keyup: _vm.handleInputJobLevel,
                          focus: _vm.handleFocusJobLevel,
                          blur: _vm.handleBlurJobLevel,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.jobLevel = $event.target.value
                          },
                        },
                      }),
                      _vm.jobLevelError
                        ? _c("div", { staticClass: "el-form-item__error" }, [
                            _vm._v(
                              "\n                        职级长度最大长度为20\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _vm.showJobLevelList
                        ? _c(
                            "ul",
                            {
                              staticClass: "job-level-list",
                              on: { scroll: _vm.handleScrollList },
                            },
                            _vm._l(_vm.jobLevelOptions, function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: item.id,
                                  class: item.isSelected
                                    ? "job-level-item selected"
                                    : "job-level-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleJobLevelItem(item, index)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _c("span", { staticClass: "item-more-btn" }, [
                        _c(
                          "span",
                          {
                            staticClass: "job-manage-link",
                            on: { click: _vm.gotoJobManage },
                          },
                          [
                            _c("i", { staticClass: "icon-job-manage" }),
                            _vm._v("职级&薪酬管理"),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _c("el-form-item", { attrs: { label: "月薪", required: true } }, [
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { required: true, prop: "minSalary" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "col-4",
                            attrs: { placeholder: "请输入最低月薪" },
                            model: {
                              value: _vm.form.minSalary,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "minSalary", _vm._n($$v))
                              },
                              expression: "form.minSalary",
                            },
                          },
                          [
                            _c("template", { slot: "suffix" }, [
                              _vm._v("K(千)  "),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { required: true, prop: "maxSalary" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "col-4",
                            attrs: { placeholder: "请输入最高月薪" },
                            model: {
                              value: _vm.form.maxSalary,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "maxSalary", _vm._n($$v))
                              },
                              expression: "form.maxSalary",
                            },
                          },
                          [
                            _c("template", { slot: "suffix" }, [
                              _vm._v("K(千)  "),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "item-more-btn help-btn" }, [
                  _c("i", { staticClass: "icon-new icon-help" }),
                  _c("div", { staticClass: "tip-wrap" }, [
                    _vm._v(
                      "\n                        根据你填写的职级自动填充对应的薪酬，增加或更改职级对应的薪酬请点击上方的“职级&薪酬管理”\n                    "
                    ),
                  ]),
                ]),
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "工作年限",
                    required: true,
                    prop: "minYearOfExperience",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "col-4",
                      attrs: { placeholder: "请选择工作年限" },
                      model: {
                        value: _vm.form.minYearOfExperience,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "minYearOfExperience", $$v)
                        },
                        expression: "form.minYearOfExperience",
                      },
                    },
                    _vm._l(_vm.workExperienceJson, function (value, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: value, value: key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "最低学历",
                    required: true,
                    prop: "minDegree",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "col-4",
                      attrs: { placeholder: "请选择最低学历" },
                      model: {
                        value: _vm.form.minDegree,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "minDegree", $$v)
                        },
                        expression: "form.minDegree",
                      },
                    },
                    _vm._l(_vm.degreeJson, function (value, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: value, value: key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "招聘人数",
                    required: true,
                    prop: "recruitingCount",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "col-4",
                    model: {
                      value: _vm.form.recruitingCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "recruitingCount", _vm._n($$v))
                      },
                      expression: "form.recruitingCount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "职位描述",
                    required: true,
                    prop: "description",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "col-16",
                    attrs: {
                      type: "textarea",
                      rows: "8",
                      maxlength: "8000",
                      placeholder:
                        "请输入8000字内的职位描述，寻访要点，职位卖点等信息",
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "职位分析",
                    required: true,
                    prop: "analysis",
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "请填写更多信息，如新增替补原因、汇报对象、团队情况、面试流程、薪资福利、寻访目标等"
                    ),
                  ]),
                  _c("el-input", {
                    staticClass: "col-16",
                    attrs: {
                      type: "textarea",
                      rows: "8",
                      maxlength: "2000",
                      placeholder: "请输入2000字内的职位分析",
                    },
                    model: {
                      value: _vm.form.analysis,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "analysis", $$v)
                      },
                      expression: "form.analysis",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "紧急程度",
                    required: true,
                    prop: "emergencyDegree",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.emergencyDegree,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "emergencyDegree", $$v)
                        },
                        expression: "form.emergencyDegree",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("紧急"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("不紧急"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "难易程度",
                    required: true,
                    prop: "difficultDegree",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.difficultDegree,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "difficultDegree", $$v)
                        },
                        expression: "form.difficultDegree",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("较难"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("容易"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h4", { staticClass: "single-job-create-form-title" }, [
                _vm._v("签约佣金"),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "签约佣金" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "col-4",
                      attrs: {
                        placeholder: "请选择",
                        required: true,
                        prop: "commissionType",
                      },
                      model: {
                        value: _vm.form.commissionType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "commissionType", $$v)
                        },
                        expression: "form.commissionType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "按比例", value: "0" },
                      }),
                      _c("el-option", {
                        attrs: { label: "固定佣金", value: "1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _vm.form.commissionType == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                required: true,
                                prop: "commissionValue",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "col-4",
                                  attrs: { maxlength: "4" },
                                  model: {
                                    value: _vm.form.commissionValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "commissionValue",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "form.commissionValue",
                                  },
                                },
                                [
                                  _c("template", { slot: "suffix" }, [
                                    _vm._v("K(千)  "),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.commissionType == 0
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                required: true,
                                prop: "commissionValue",
                                rules: _vm.rules.commissionPercentValue,
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "col-4",
                                  model: {
                                    value: _vm.form.commissionValue,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "commissionValue", $$v)
                                    },
                                    expression: "form.commissionValue",
                                  },
                                },
                                [
                                  _c("template", { slot: "suffix" }, [
                                    _vm._v("%  "),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "保证期",
                    required: true,
                    prop: "guaranteePeriod",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "col-4",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.guaranteePeriod,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "guaranteePeriod", _vm._n($$v))
                        },
                        expression: "form.guaranteePeriod",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "6个月", value: 6 } }),
                      _c("el-option", { attrs: { label: "3个月", value: 3 } }),
                      _c("el-option", { attrs: { label: "无", value: 0 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.canPublic,
                      expression: "canPublic",
                    },
                  ],
                  staticClass: "single-job-create-publish-title",
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { disabled: !_vm.isPublic },
                      model: {
                        value: _vm.form.IsPublic,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "IsPublic", $$v)
                        },
                        expression: "form.IsPublic",
                      },
                    },
                    [_vm._v("发布到市场")]
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        width: "70",
                        trigger: "click",
                        content: "猎企发布",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "publish-A2A",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [_vm._v("A2A")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.IsPublic
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "市场佣金", required: true } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "col-4",
                              attrs: {
                                placeholder: "请选择",
                                required: true,
                                prop: "PublicCommissionType",
                                disabled: _vm.isPublic,
                              },
                              model: {
                                value: _vm.form.PublicCommissionType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "PublicCommissionType",
                                    $$v
                                  )
                                },
                                expression: "form.PublicCommissionType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "按比例", value: "0" },
                              }),
                              _c("el-option", {
                                attrs: { label: "固定佣金", value: "1" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    required: true,
                                    prop: "PublicCommissionValue",
                                  },
                                },
                                [
                                  _vm.form.PublicCommissionType == 0
                                    ? _c(
                                        "el-input",
                                        {
                                          staticClass: "col-4",
                                          attrs: {
                                            maxlength: "2",
                                            disabled: _vm.isPublic,
                                          },
                                          model: {
                                            value:
                                              _vm.form.PublicCommissionValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "PublicCommissionValue",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "form.PublicCommissionValue",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "suffix" }, [
                                            _vm._v("%  "),
                                          ]),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.form.PublicCommissionType == 1
                                    ? _c(
                                        "el-input",
                                        {
                                          staticClass: "col-4",
                                          attrs: {
                                            maxlength: "4",
                                            disabled: _vm.isPublic,
                                          },
                                          model: {
                                            value:
                                              _vm.form.PublicCommissionValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "PublicCommissionValue",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "form.PublicCommissionValue",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "suffix" }, [
                                            _vm._v("K(千)  "),
                                          ]),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.publishErrorMsgShow,
                                  expression: "publishErrorMsgShow",
                                },
                              ],
                              staticClass: "publish-error",
                            },
                            [_vm._v(_vm._s(_vm.publishErrorMsg))]
                          ),
                        ],
                        1
                      ),
                      !(
                        _vm.isPublic &&
                        _vm.form.HeadhuntingFirmCommissionValue == 0 &&
                        _vm.form.OtherFirmCommissionValue == 0
                      )
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分佣比例",
                                prop: "HeadhuntingFirmCommissionValue",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "col-4 input-commission-value",
                                  attrs: {
                                    required: true,
                                    disabled: _vm.isPublic,
                                  },
                                  model: {
                                    value:
                                      _vm.form.HeadhuntingFirmCommissionValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "HeadhuntingFirmCommissionValue",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression:
                                      "form.HeadhuntingFirmCommissionValue",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "prefix" },
                                      slot: "prefix",
                                    },
                                    [_vm._v("猎企")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "suffix" },
                                      slot: "suffix",
                                    },
                                    [_vm._v("%  ")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "OtherFirmCommissionValue",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticClass:
                                            "col-4 input-commission-value",
                                          attrs: { disabled: _vm.isPublic },
                                          model: {
                                            value:
                                              _vm.form.OtherFirmCommissionValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "OtherFirmCommissionValue",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "form.OtherFirmCommissionValue",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "prefix" },
                                              slot: "prefix",
                                            },
                                            [_vm._v("非猎企")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "suffix" },
                                              slot: "suffix",
                                            },
                                            [_vm._v("%  ")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "保证期",
                            required: true,
                            prop: "PublicGuaranteePeriod",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "col-4",
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.isPublic,
                              },
                              model: {
                                value: _vm.form.PublicGuaranteePeriod,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "PublicGuaranteePeriod",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "form.PublicGuaranteePeriod",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "6个月", value: 6 },
                              }),
                              _c("el-option", {
                                attrs: { label: "3个月", value: 3 },
                              }),
                              _c("el-option", {
                                attrs: { label: "无", value: 0 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退款约定",
                            required: true,
                            prop: "RefundPolicyDescription",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "col-16",
                            attrs: {
                              type: "textarea",
                              rows: "3",
                              resize: "none",
                              disabled: _vm.isPublic,
                            },
                            model: {
                              value: _vm.form.RefundPolicyDescription,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "RefundPolicyDescription",
                                  $$v
                                )
                              },
                              expression: "form.RefundPolicyDescription",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "设置可见猎企：",
                            prop: "visibleFirmIdList",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "col-8 input-visible-firm",
                              attrs: {
                                multiple: "",
                                placeholder: "请选择可见猎企",
                              },
                              model: {
                                value: _vm.visibleFirmIdList,
                                callback: function ($$v) {
                                  _vm.visibleFirmIdList = $$v
                                },
                                expression: "visibleFirmIdList",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部猎企(默认)", value: 0 },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.firmSelectedAll.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _vm._l(_vm.companyList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  staticClass: "company-item",
                                  attrs: {
                                    label: item.name,
                                    value: item.firmId,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.firmSelected(index)
                                    },
                                  },
                                })
                              }),
                              _vm.hasMoreCompany
                                ? _c("el-option", {
                                    staticClass: "get-more-btn",
                                    attrs: { label: "加载更多" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.getCompanyList.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "span",
                                { attrs: { slot: "empty" }, slot: "empty" },
                                [_vm._v("暂无数据")]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "匿名当前公司名" } },
                        [
                          _c("el-input", {
                            staticClass: "col-8",
                            attrs: {
                              placeholder:
                                "输入您希望展示在市场的公司匿名称（选填）",
                            },
                            model: {
                              value: _vm.form.AnonymousFirmName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "AnonymousFirmName", $$v)
                              },
                              expression: "form.AnonymousFirmName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "single-job-create-form-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("提交")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "single-job-create-form-btn",
                      on: { click: _vm.cancelEdit },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("customer-create-dialog", {
            ref: "customerCreateDialog",
            on: { "refresh-customer-data": _vm.getCustomerData },
          }),
          _c("project-create-dialog", {
            ref: "projectCreateDialog",
            on: { "refresh-project-list": _vm.refreshPorjectList },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "grade-salary-dialog",
          attrs: {
            title: "职级&薪酬管理",
            visible: _vm.dialogVisible,
            width: "1100px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "add-customer-grade-btn",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.editCustomerGrade(false)
                    },
                  },
                },
                [_vm._v("\n                新增职级\n            ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                },
                [
                  _c("customer-grade-list", {
                    ref: "customerGradeList",
                    attrs: { gradeList: _vm.dataList, fromPage: "createJob" },
                    on: {
                      "edit-grade": (grade) =>
                        _vm.editCustomerGrade(true, grade),
                      "update-list": () => _vm.getGradeList(),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("cus-grade-edit-dialog", {
        ref: "cusGradeEditDialog",
        attrs: {
          customerId: _vm.form.targetCompany,
          customerName: _vm.form.customerName,
        },
        on: {
          "update-list": function ($event) {
            return _vm.getGradeList()
          },
        },
      }),
      _c("div", { staticClass: "lbd-sign" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "single-job-create-top" }, [
      _c("h3", { staticClass: "single-job-create-title" }, [
        _vm._v("复制创建职位"),
      ]),
      _c("div", { staticClass: "single-job-create-tip" }, [
        _c("i", { staticClass: "el-icon-info" }),
        _vm._v("创建公司内部职位，你的同事可以接单哦"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }