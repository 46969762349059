<template>
    <div class="job-create">
        <div class="single-job-create">
            <div class="single-job-create-top">
                <h3 class="single-job-create-title">复制创建职位</h3>
                <div class="single-job-create-tip"><i class="el-icon-info"></i>创建公司内部职位，你的同事可以接单哦</div>
            </div>

            <el-form class="single-job-create-form" ref="form" :model="form" :rules="rules" label-width="150px" v-loading="loading">
                <h4 class="single-job-create-form-title">职位</h4>

                <el-form-item label="职位名称" :required="true" prop="name">
                    <el-input class="col-8" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="职位类别" :required="true" prop="category">
                    <el-cascader
                        class="col-8"
                        placeholder="请选择职位类别"
                        v-model="form.category"
                        :options="jobCategories.slice(1)"
                        :show-all-levels="false"
                        :props="{
                            expandTrigger: 'hover',
                            value: 'id',
                            label: 'name',
                            children: 'subJobCategories',
                            emitPath: false
                        }"
                        @change="handleCategoryChange"
                    ></el-cascader>
                </el-form-item>
                <el-form-item
                    label="所属公司"
                    prop="customerName">
                    <customer-select
                        ref="customerSelect"
                        class="col-8"
                        placeholder="请选择所属公司"
                        @customerSelected="handleCustomerNodeClick"
                    ></customer-select>
                    <span
                        class="add-customer-btn"
                        @click="handleCustomerCreate">
                        <i class="el-icon-circle-plus-outline"></i>
                        <em>{{isCustomer ? '添加客户': '申请创建客户'}}</em>
                    </span>
                </el-form-item>
                <el-form-item
                    label="所属BG/BU/部门"
                    prop="customerDepartmentId">
                    <span class="col-8">
                        <customer-department-select
                            ref="departmentSelect"
                            :isSearchOnlyLeaf="true"
                            :isOnlyLeaf="true"
                            :customerId="form.targetCompany"
                            :isDisabled="!form.targetCompany"
                            :departmentId="form.customerDepartmentId"
                            placeholder="请输入部门关键字，至少两位"
                            @update-department="updateDepartmentId"
                        ></customer-department-select>
                    </span>

                    <span
                        class="add-department-btn"
                        v-if="form.targetCompany"
                        @click="dispatch('App', 'createDepartment', {
                            customerId: form.targetCompany,
                            customerName: form.customerName
                        })">
                        <i class="el-icon-circle-plus-outline"></i>
                        <em>添加BG/BU/部门</em>
                    </span>
                </el-form-item>
                <el-form-item
                    v-if="!form.IsPublic"
                    label="所属项目"
                    prop="projectId">
                    <project-select
                        class="col-8"
                        ref="projectSelect"
                        placeholder="请输入所属项目"
                        :isDisabled="!(form.targetCompany && form.customerDepartmentId)"
                        @projectSelected="changeProject"
                    ></project-select>
                    <span
                        class="add-customer-btn"
                        @click="handleProjectCreate">
                        <i class="el-icon-circle-plus-outline"></i>
                        <em>添加项目</em>
                    </span>
                </el-form-item>
                <el-form-item label="工作地区" :required="true" prop="locations">
                    <el-input class="col-8" readOnly="true" @click.native="showCitySelect" placeholder="请选择，最多选择5项" :value="locationName"></el-input>
                    <div class="hot-city">
                        <span v-for="(city, key) in hotCityMap" :key="key" @click="selectHotCity(city.label, city.value)">{{city.label}}</span>
                    </div>
                    <select-city-dialog ref="selectCityDialog" @city-selected="handleCitySelect"></select-city-dialog>
                </el-form-item>
                <el-form-item label="详细地址">
                    <el-input class="col-8" v-model="form.address" maxLength="50"></el-input>
                </el-form-item>
                <el-form-item label="职级" :class="jobLevelError ? 'is-error' : ''">
                    <div class="job-level-wrap" id="jobLevelList">
                        <input
                            class="job-level-ipt"
                            :class="jobLevelError ? 'el-input__inner' : ''"
                            v-model="jobLevel"
                            @keyup="handleInputJobLevel"
                            @focus="handleFocusJobLevel"
                            @blur="handleBlurJobLevel"
                            placeholder="请选填职级，多个职级请用“/”隔开，如：P6/P7/P8"
                        />
                        <div class="el-form-item__error" v-if="jobLevelError">
                            职级长度最大长度为20
                        </div>
                        <ul class="job-level-list" v-if="showJobLevelList" @scroll="handleScrollList">
                            <li :class="item.isSelected?'job-level-item selected':'job-level-item'" v-for="(item,index) in jobLevelOptions" :key="item.id" @click="handleJobLevelItem(item,index)">{{item.name}}</li>
                        </ul>
                        <span class="item-more-btn">
                            <span class="job-manage-link" @click="gotoJobManage"><i class="icon-job-manage"></i>职级&薪酬管理</span>
                        </span>
                    </div>

                </el-form-item>
                <el-form-item label="月薪" :required="true">
                    <div class="col-4">
                        <el-form-item :required="true" prop="minSalary">
                            <el-input class="col-4" v-model.number="form.minSalary" placeholder="请输入最低月薪">
                                <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-4">
                        <el-form-item :required="true" prop="maxSalary">
                            <el-input class="col-4" v-model.number="form.maxSalary" placeholder="请输入最高月薪">
                                <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <span class="item-more-btn help-btn">
                        <i class="icon-new icon-help">
                        </i>
                        <div class="tip-wrap">
                            根据你填写的职级自动填充对应的薪酬，增加或更改职级对应的薪酬请点击上方的“职级&薪酬管理”
                        </div>
                    </span>
                </el-form-item>

                <el-form-item label="工作年限" :required="true" prop="minYearOfExperience">
                    <el-select class="col-4" v-model="form.minYearOfExperience" placeholder="请选择工作年限">
                        <el-option v-for="(value, key) in workExperienceJson" :key="key" :label="value" :value="key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="最低学历" :required="true" prop="minDegree">
                    <el-select class="col-4" v-model="form.minDegree" placeholder="请选择最低学历">
                        <el-option v-for="(value, key) in degreeJson" :key="key" :label="value" :value="key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="招聘人数" :required="true" prop="recruitingCount">
                    <el-input class="col-4" v-model.number="form.recruitingCount"></el-input>
                </el-form-item>
                <el-form-item label="职位描述" :required="true" prop="description">
                    <el-input class="col-16" type="textarea" rows="8" v-model="form.description" maxlength="8000" placeholder="请输入8000字内的职位描述，寻访要点，职位卖点等信息"></el-input>
                </el-form-item>
                <el-form-item label="职位分析" :required="true" prop="analysis">
                    <p>请填写更多信息，如新增替补原因、汇报对象、团队情况、面试流程、薪资福利、寻访目标等</p>
                    <el-input class="col-16" type="textarea" rows="8" v-model="form.analysis" maxlength="2000" placeholder="请输入2000字内的职位分析"></el-input>
                </el-form-item>
                <el-form-item label="紧急程度" :required="true" prop="emergencyDegree">
                    <el-radio-group v-model="form.emergencyDegree">
                        <el-radio-button label="1">紧急</el-radio-button>
                        <el-radio-button label="0">不紧急</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="难易程度" :required="true" prop="difficultDegree">
                    <el-radio-group v-model="form.difficultDegree">
                        <el-radio-button label="1">较难</el-radio-button>
                        <el-radio-button label="0">容易</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <h4 class="single-job-create-form-title">签约佣金</h4>
                <!-- :required="true" -->
                <el-form-item label="签约佣金">
                    <el-select class="col-4" v-model="form.commissionType" placeholder="请选择" :required="true" prop="commissionType">
                        <el-option label="按比例" value="0"></el-option>
                        <el-option label="固定佣金" value="1"></el-option>
                    </el-select>
                    <div class="col-4">
                        <el-form-item 
                            :required="true" 
                            v-if="form.commissionType == 1" 
                            prop="commissionValue">
                            <el-input 
                                class="col-4" 
                                v-model.number="form.commissionValue" 
                                maxlength="4"
                                >
                                <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item 
                            :required="true" 
                            v-if="form.commissionType == 0" 
                            prop="commissionValue"
                            :rules="rules.commissionPercentValue"
                            >
                            <el-input 
                                class="col-4" 
                                v-model="form.commissionValue"
                                >
                                <!-- v-model.number="form.commissionValue" 
                                prop="commissionValue" 
                                maxlength="2" -->
                                <template slot="suffix">%&nbsp;&nbsp;</template>
                            </el-input>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="保证期" :required="true" prop="guaranteePeriod">
                    <el-select class="col-4" v-model.number="form.guaranteePeriod" placeholder="请选择">
                        <el-option label="6个月" :value="6"></el-option>
                        <el-option label="3个月" :value="3"></el-option>
                        <el-option label="无" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                
                <div class="single-job-create-publish-title" v-show="canPublic">
                    <el-checkbox v-model="form.IsPublic" :disabled="!isPublic">发布到市场</el-checkbox>
                    <el-popover
                        placement="right"
                        width="70"
                        trigger="click"
                        content="猎企发布">
                        <el-button slot="reference" class="publish-A2A">A2A</el-button>
                    </el-popover>
                </div>

                <div v-if="form.IsPublic">
                    <el-form-item label="市场佣金" :required="true">
                        <el-select
                            class="col-4"
                            v-model="form.PublicCommissionType"
                            placeholder="请选择"
                            :required="true"
                            prop="PublicCommissionType"
                            :disabled="isPublic"
                        >
                            <el-option label="按比例" value="0"></el-option>
                            <el-option label="固定佣金" value="1"></el-option>
                        </el-select>
                        <div class="col-4">
                            <el-form-item :required="true" prop="PublicCommissionValue">
                                <el-input
                                    v-if="form.PublicCommissionType == 0"
                                    class="col-4"
                                    v-model.number="form.PublicCommissionValue"
                                    maxlength="2"
                                    :disabled="isPublic"
                                >
                                    <template slot="suffix">%&nbsp;&nbsp;</template>
                                </el-input>
                                <el-input
                                    v-if="form.PublicCommissionType == 1"
                                    class="col-4"
                                    v-model.number="form.PublicCommissionValue"
                                    maxlength="4"
                                    :disabled="isPublic"
                                >
                                    <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                                </el-input>
                            </el-form-item>
                        </div>
                        <span class="publish-error" v-show="publishErrorMsgShow">{{publishErrorMsg}}</span>
                    </el-form-item>
                    <el-form-item
                        label="分佣比例"
                        prop="HeadhuntingFirmCommissionValue"
                        v-if="!(isPublic && form.HeadhuntingFirmCommissionValue == 0 && form.OtherFirmCommissionValue == 0)"
                    >
                        <el-input
                            class="col-4 input-commission-value"
                            v-model.number="form.HeadhuntingFirmCommissionValue"
                            :required="true"
                            :disabled="isPublic"
                        >
                            <span slot="prefix">猎企</span>
                            <span slot="suffix">%&nbsp;&nbsp;</span>
                        </el-input>
                        <div class="col-4">
                            <el-form-item
                                prop="OtherFirmCommissionValue"
                            >
                                <el-input
                                    class="col-4 input-commission-value"
                                    v-model.number="form.OtherFirmCommissionValue"
                                    :disabled="isPublic"
                                >
                                    <span slot="prefix">非猎企</span>
                                    <span slot="suffix">%&nbsp;&nbsp;</span>
                                </el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label="保证期" :required="true" prop="PublicGuaranteePeriod">
                        <el-select
                            class="col-4"
                            v-model.number="form.PublicGuaranteePeriod"
                            placeholder="请选择"
                            :disabled="isPublic"
                        >
                            <el-option label="6个月" :value="6"></el-option>
                            <el-option label="3个月" :value="3"></el-option>
                            <el-option label="无" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="退款约定" :required="true" prop="RefundPolicyDescription">
                        <el-input
                            class="col-16"
                            type="textarea"
                            rows="3"
                            v-model="form.RefundPolicyDescription"
                            resize="none"
                            :disabled="isPublic"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="设置可见猎企：" prop="visibleFirmIdList">
                        <el-select
                            multiple
                            class="col-8 input-visible-firm"
                            placeholder="请选择可见猎企"
                            v-model="visibleFirmIdList"
                        >
                            <el-option
                                label="全部猎企(默认)"
                                :value="0"
                                @click.native.prevent="firmSelectedAll"
                            ></el-option>
                            <el-option
                                v-for="(item, index) in companyList"
                                :label="item.name"
                                :key="index"
                                :value="item.firmId"
                                class="company-item"
                                @click.native="firmSelected(index)"
                            ></el-option>
                            <el-option
                                label="加载更多"
                                class="get-more-btn"
                                @click.native.stop="getCompanyList"
                                v-if="hasMoreCompany"
                            ></el-option>
                            <span slot="empty">暂无数据</span>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="匿名当前公司名">
                        <el-input class="col-8" v-model="form.AnonymousFirmName" placeholder="输入您希望展示在市场的公司匿名称（选填）"></el-input>
                    </el-form-item>
                </div>

                <el-form-item>
                    <el-button class="single-job-create-form-btn" type="primary" @click="onSubmit">提交</el-button>
                    <el-button class="single-job-create-form-btn" @click="cancelEdit">取消</el-button>
                </el-form-item>

            </el-form>
            
            <customer-create-dialog ref="customerCreateDialog" @refresh-customer-data="getCustomerData"></customer-create-dialog>
            <project-create-dialog ref="projectCreateDialog" @refresh-project-list="refreshPorjectList"></project-create-dialog>
        </div>

        <el-dialog
            title="职级&薪酬管理"
            class="grade-salary-dialog"
            :visible.sync="dialogVisible"
            width="1100px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
            <div>
                <el-button
                    type="primary"
                    icon="el-icon-circle-plus-outline"
                    class="add-customer-grade-btn"
                    @click="editCustomerGrade(false)">
                    新增职级
                </el-button>
                <div v-loading="listLoading">
                    <customer-grade-list
                        ref="customerGradeList"
                        :gradeList="dataList"
                        :fromPage="'createJob'"
                        @edit-grade="(grade) => editCustomerGrade(true, grade)"
                        @update-list="() => getGradeList()"
                    ></customer-grade-list>
                </div>
            </div>
        </el-dialog>
        <cus-grade-edit-dialog
            ref="cusGradeEditDialog"
            :customerId="form.targetCompany"
            :customerName="form.customerName"
            @update-list="getGradeList()"
        ></cus-grade-edit-dialog>

        <div class="lbd-sign"></div>
    </div>
</template>

<script>
    import CustomerService from '#/js/service/customerService.js';

    import CusGradeEditDialog from '#/views/customers/component/cus-grade-edit-dialog.vue';
    import CustomerGradeList from '#/views/customers/component/customer-grade-list.vue';
    import CustomerSelect from '#/component/common/customer-select.vue';
    import CustomerCreateDialog from '#/views/customer-list/component/customerCreateDialog.vue';
    import ProjectSelect from '#/component/common/project-select.vue';
    import ProjectCreateDialog from '#/views/project/component/create-project-dialog.vue';
    import SelectCityDialog from '#/component/common/dialog/select-city-dialog.vue';
    import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue';

    import { getCityTextById } from '#/js/util/provinceCity.js';
    import {html as htmlTest} from '#/js/util/validators.js';
    import emitter from '#/js/mixins/emitter.js';

    import { 
        job as jobUrl, 
        company as companyUrl, 
        data as dataUrl, 
        firm as firmUrl 
        } from '#/js/config/api.json';
    import { 
        workExperience as workExperienceJson, 
        degree as degreeJson 
        } from '#/js/config/searchField.json';
    import hotCityMap from '#/js/config/hotCity.json';

    export default {
        name: "singleJobCreate",
        components: {
            CusGradeEditDialog,
            CustomerGradeList,
            CustomerSelect,
            CustomerCreateDialog,
            CustomerDepartmentSelect,
            ProjectSelect,
            ProjectCreateDialog,
            SelectCityDialog
        },
        mixins: [emitter],
        data() {
            return {
                listLoading: false,
                dataList: [],
                dialogVisible: false,
                loading: false,
                hotCityMap: hotCityMap,
                workExperienceJson: workExperienceJson,
                degreeJson: degreeJson,
                form: {
                    name: '',
                    category: '',
                    customerName: '',
                    customerDepartmentId: '',
                    customerDepartmentName: '',
                    projectId: '',
                    targetCompany: '',
                    locations: [],
                    address: '',
                    minSalary: 0,
                    maxSalary: 0,
                    minYearOfExperience: "0",
                    minDegree: "0",
                    recruitingCount: 0,
                    description: '',
                    analysis: '',
                    emergencyDegree: 0,
                    difficultDegree: 0,
                    guaranteePeriod: 6,
                    commissionType: "0",
                    commissionValue: '',
                    IsPublic: false,//是否发布为A2A市场职位，因市场入口隐藏，该选项不放开选择
                    PublicCommissionType: "0",
                    PublicCommissionValue: 0,
                    HeadhuntingFirmCommissionValue: 0,
                    OtherFirmCommissionValue: 0,
                    PublicGuaranteePeriod: 6,
                    RefundPolicyDescription: '保证期内离职，退还100%的服务费',
                    AnonymousFirmName: '',
                },
                locationName: '',
                rules: {
                    name: [
                        { required: true, message: '请输入职位名称', trigger: ['blur', 'change'] },
                        { max: 128, message: '职位名称最大长度为128', trigger: 'blur' },
                        { validator: this.validateJobName, trigger: ['change', 'blur'] }
                    ],
                    category: [
                        { required: true, message: '请选择职位类别', trigger: ['blur', 'change'] }
                    ],
                    customerName: [
                        { required: true, message: '请选择所属公司', trigger: 'change' }
                    ],
                    customerDepartmentId: [
                        { required: true, message: '请选择所属BG/BU/部门', trigger: 'change' }
                    ],
                    projectId: [
                        // { required: true, message: '请选择所属项目', trigger: 'change' }
                    ],
                    locations: [
                        { required: true, message: '请选择工作地区', trigger: ['blur', 'change'] }
                    ],
                    minSalary: [
                        { required: true, message: '请填写最低月薪', trigger: ['blur', 'change'] },
                        { type: 'number', min: 1, max: 999, message: '最低月薪的取值范围是1-999', trigger: 'blur' }
                    ],
                    maxSalary: [
                        { required: true, message: '请填写最高月薪', trigger: ['blur', 'change'] },
                        { type: 'number', min: 1, max: 999, message: '最高月薪的取值范围是1-999', trigger: 'blur' }
                    ],
                    recruitingCount: [
                        { required: true, message: '请填写招聘人数', trigger: ['blur', 'change'] },
                        { type: 'number', min: 1, max: 99, message: '招聘人数的范围在1-99之间', trigger: 'blur' }
                    ],
                    description: [
                        { required: true, message: '请填写职位描述', trigger: ['change', 'blur'] },
                        { validator: this.DescriptionValidator, trigger: ['change', 'blur'] }
                    ],
                    analysis: [
                        { required: true, message: '请填写职位分析', trigger: ['change', 'blur'] },
                        { validator: this.AnalysisValidator, trigger: ['change', 'blur'] }
                    ],
                    commissionValue: [
                        { required: true, message: '请填写签约佣金', trigger: ['change', 'blur'] }
                    ],
                    commissionPercentValue: [
                        { required: true, message: '请填写签约佣金比例', trigger: ['change', 'blur'] },
                        { validator: this.validateCommissionValue, trigger: ['change', 'blur'] }
                    ],
                    PublicCommissionValue: [
                        { required: true, message: '请填写市场佣金', trigger: ['change', 'blur'] },
                        { validator: this.PublicCommissionValidator, trigger: ['change', 'blur'] }
                    ],
                    HeadhuntingFirmCommissionValue: [{
                        required: true, message: '请填写猎企类型公司分佣比例', trigger: 'blur'
                    }, {
                        type: 'number', message: '分佣比例必须输入数字格式', trigger: 'blur'
                    }, {
                        validator: this.validateHFCommissionValue, trigger: 'blur'
                    }],
                    OtherFirmCommissionValue: [{
                        required: true, message: '请填写非猎企类型公司分佣比例', trigger: 'blur'
                    }, {
                        type: 'number', message: '分佣比例必须输入数字格式', trigger: 'blur'
                    }, {
                        validator: this.validateOFCommissionValue, trigger: 'blur'
                    }],
                    RefundPolicyDescription:[
                        { required: true, message: '请填写退款约定', trigger: ['change', 'blur'] },
                        { max: 500, message: '退款约定最长支持500个字符', trigger: 'blur' }
                    ]
                },
                customerList: [],
                jobCategories: [],
                jobLevelSelectedArr:[],//职级选择数组
                jobLevelOptions:[],
                showJobLevelList: false,//显示职级选项
                jobLevel: '',//职级
                hasInputJobLevel: false,//用于判断是否有输入职级的标志
                curPage: 1,
                pageSize: 10,
                isNoMore: false,
                isLoading: false,
                isEdit: false,
                isEditAndCommissionValueChanged: false,
                isEditAndPublicCommissionValueChanged: false,
                publishErrorMsg:'',
                canPublic:false,
                minFixed:15,
                minProportion:10,
                companyList: [],
                visibleFirmIdList: [0],
                hasMoreCompany: false,
                jobLevelError: false,
                headhuntingFirmCommissionValue: 80,
                otherFirmCommissionValue: 60,
                isPublic: false
            }
        },
        computed:{
            publishErrorMsgShow(){
                if(this.form.commissionType == 1 && this.form.PublicCommissionType ==0){
                    this.publishErrorMsg='内部佣金为固定佣金时，市场佣金也需要是固定佣金';
                    return true;
                }
                if(this.form.commissionType==0 && this.form.PublicCommissionType==1){
                    if(parseInt(this.form.commissionValue)*this.form.minSalary*12*10 < this.form.PublicCommissionValue*1000){
                        this.publishErrorMsg='您的市场佣金大于签约佣金，请调整';
                        return true;
                    }
                }else{
                    if(this.form.commissionValue < this.form.PublicCommissionValue){
                        this.publishErrorMsg='您的市场佣金大于签约佣金，请调整';
                        return true;
                    }
                }
                return false;
            },
            isAdministrator() {
                return this.$store.state.user.userInfo.isAdministrator || false;
            },
            isBusinessPermission() {
                return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('Business');
            },
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            isCustomer() {
                return this.userInfo.privilegeCodeList?.includes('CustomerManage');
            },
        },
        watch: {
            'form.commissionType': function(val) {
                if(this.isEdit && !this.isEditAndCommissionValueChanged) {
                    this.isEditAndCommissionValueChanged = true;
                }else {
                    this.form.commissionValue = ''
                }
            },
            'form.PublicCommissionType': function(val) {
                if(this.isEdit && !this.isEditAndPublicCommissionValueChanged) {
                    this.isEditAndPublicCommissionValueChanged = true;
                }else {
                    this.form.PublicCommissionValue = ''
                }
            }
        },
        mounted() {
            if(!this.isAdministrator && !this.isBusinessPermission){
                shortTips("权限不足，请联系管理员")
                setTimeout(() => {
                    location.href = "/Headhunting/#/";
                }, 2000)
            }

            this.getJobCategories();
            document.addEventListener('click',()=>{
                this.showJobLevelList = false;
            })
            document.getElementById('jobLevelList').addEventListener('click',(e)=>{
                e.stopPropagation();
            })

            //获取职位信息
            if(this.getQueryString('jobId') || this.getQueryString('applicationId')){
                let firmName = this.$store.state.user.userInfo.firmShortName;
                // document.title = `复制创建职位_${firmName}_猎必得_最有效的O2O猎头招聘平台'`;
                this.isEdit = true;
                this.jobId = this.getQueryString('jobId');
                this.getJobData(this.jobId);
            }
            this.userCanPublish();
        },
        methods: {
            userCanPublish(){
                _request({
                    url: "/User/CanPublic ",
                    method: "POST"
                }).then(res => {
                    this.canPublic = res.canPublic
                    if(this.canPublic) {
                        this.getCompanyList();
                        this.getFirmCommissionValue();
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            getCompanyList() {
                _request({
                    url: companyUrl.white_list_firms,
                    method: "GET",
                    data: {
                        start: this.fetchCompanyStart,
                        take: 20
                    }
                }).then((res) => {
                    if(res.length == 20) {
                        this.hasMoreCompany = true;
                    } else {
                        this.hasMoreCompany = false;
                    }
                    if(res.length > 0) {
                        res.forEach((item) => {
                            item.selected = false;
                        })
                    }
                    this.companyList = this.companyList.concat(res);
                })
            },
            getPublicToMarketJson() {
                return  _request({
                    url: jobUrl.publicize_info.replace(/\%p/ig, this.jobData.id),
                    method: "GET",
                    data: {
                        _: new Date().getTime()
                    }
                })
            },
            firmSelectedAll() {
                this.visibleFirmIdList = [0];
                this.companyList.forEach((item) => {
                    item.selected = false;
                });
            },
            firmSelected(index) {
                let visibleFirmIdList = this.visibleFirmIdList;
                if(visibleFirmIdList[0] == 0) {
                    this.visibleFirmIdList.shift();
                }
                if(visibleFirmIdList[0] !== 0 && visibleFirmIdList.length == this.companyList.length) {
                    this.visibleFirmIdList = [0];
                }
                let selected = this.companyList[index].selected;
                this.companyList[index].selected = !selected;
            },
            PublicCommissionValidator(rule, value, callback){
                if(this.form.PublicCommissionType == 0){
                    if(value<this.minProportion){
                        callback(new Error(`市场佣金比例不能低于${this.minProportion}%`))
                    }else if(this.form.minSalary*value*12 < this.minFixed*100){
                        callback(new Error(`最低市场佣金不能低于${this.minFixed}K，请调整`))
                    }else{
                        callback();
                    }
                }else{
                    if(value<this.minFixed){
                        callback(new Error(`固定市场佣金不能少于${this.minFixed}k(千)`))
                    }else{
                        callback();
                    }
                }
            },
            DescriptionValidator(rule, value, callback) {
                if(htmlTest(value)) {
                    callback(new Error("请先删除“<字母>”再提交"));
                } else {
                    callback();
                }
            },
            AnalysisValidator(rule, value, callback) {
                if(htmlTest(value)) {
                    callback(new Error("请先删除“<字母>”再提交"));
                } else {
                    callback();
                }
            },
            validateJobName(rule, value, callback) {
                // 职位名称仅支持“-”和“（）”、“()”这三种符号存在
                if(!/^[a-zA-Z0-9-\u4e00-\u9fa5()（）\.\#\+\-\s]+$/g.test(value)) {
                    callback(`职位名称仅支持“-”和“（）”、“()”、“.”、“+”、“#”这六种符号存在。`);
                }else if(/\/|\,|\\|\||\、/g.test(value) ||
                    (/.*Java|后端.*/ig.test(value) && /.*前端.*/ig.test(value)) ||
                    (/.*销售|售前.*/ig.test(value) && /.*研发|开发.*/ig.test(value)) 
                    ) {
                    callback(new Error(`不允许多个职位信息混编在一起，请确保一个职位信息创建一个对应系统职位，同时职位名称仅支持“-”和“（）”、“()”这三种符号存在。`));
                } else {
                    callback();
                }
            },
            handleCustomerCreate() {
                this.$refs.customerCreateDialog.show();
            },
            handleProjectCreate() {
                this.$refs.projectCreateDialog.show();
            },
            onSubmit() {
                this.$refs.form.validate((valid) => {
                    if(!valid || this.jobLevelError) {
                        setTimeout(() =>{
                            if($('.el-form-item__error')[0]) {
                                let bodyScroll = document.body.scrollTop || document.documentElement.scrollTop;
                                let topDistance = bodyScroll + $('.el-form-item__error')[0].getBoundingClientRect().top - 100;
                                document.documentElement.scrollTop = topDistance;
                                document.body.scrollTop = topDistance;
                            }
                        }, 300)
                    }

                    if(this.form.IsPublic && this.publishErrorMsgShow) {
                        return false;
                    }
                    if (valid && !this.jobLevelError) {
                        this.loading = true;
                        let data = Object.assign({}, this.form, {
                            __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                        }),
                            visibleFirmIdArr = [];
                        
                        if(this.visibleFirmIdList[0] == 0) {
                            visibleFirmIdArr = ["00000000-0000-0000-0000-000000000000"];
                        } else {
                            visibleFirmIdArr = this.visibleFirmIdList;
                        }
                        data = Object.assign(data,{
                            expectedRank: this.jobLevel,
                            visibleFirmIds: visibleFirmIdArr.join(","),
                        })

                        let self = this;
                        if(this.form.IsPublic) {
                            delete data.projectId;
                            _request({
                                url: '/Headhunting/A2AJob/Create',
                                method: 'POST',
                                data: data
                            })
                            .then(res => {
                                // shortTips('A2A职位发布成功，等待管理员审核...');
                                // setTimeout(() => {
                                //     // location.href = res.jobDetailUrl;
                                //     this.$router.push('/myOrders/a2aAuditJob');
                                // }, 2000)
                                this.$confirm('您创建的职位已提交，请等待工作人员审批', '提示', {
                                        confirmButtonText: '继续创建',
                                        cancelButtonText: '返回列表',
                                        type: 'warning',
                                        showClose: false,
                                    })
                                    .then(() => {
                                        this.$router.push('/jobCreate/single');
                                        this.form = this.$options.data().form;
                                        this.locationName = '';
                                        this.jobLevel = '';
                                    })
                                    .catch(() => {
                                        this.$router.push('/myOrders/a2aAuditJob');
                                    });

                                // this.$alert('您创建的职位已提交，请等待工作人员审批', '提示', 
                                //     {
                                //         confirmButtonText: '好的',
                                //         callback: action => {
                                //             this.$router.push('/myOrders/a2aAuditJob');
                                //         }
                                //     });
                            })
                            .finally(() => {
                                self.loading = false;
                            });
                        } else {
                            _request({
                                url: '/Headhunting/Job/Create',
                                method: 'POST',
                                data: data
                            }).then(res => {
                                shortTips('发布成功，即将跳转至职位详情页面...');
                                setTimeout(() => {
                                    // location.href = res.jobDetailUrl;
                                    location.href = `/#${res.jobDetailUrl.split('#')[1]}`;
                                }, 2000)
                            }).finally(() => {
                                self.loading = false;
                            });
                        }

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getDataMessage(data) {
                if(!data) {
                    return '';
                }
                let msgArr = [];
                Object.keys(data).forEach(key =>{
                    msgArr = msgArr.concat(data[key]);
                })
                return msgArr.join(',');
            },
            getCustomerData() {
                this.$refs.customerSelect.getCustomerList();
            },
            getJobCategories() {
                _request({
                    url: dataUrl.job_category,
                    method: 'GET',
                    apiType: 'oldApi',
                }).then(res => {
                    this.jobCategories = res.subJobCategories;
                })
                // $.get(dataUrl.job_category).then((result) => {
                //     this.jobCategories = result.subJobCategories;
                // });
            },
            handleCustomerNodeClick(data) {
                this.form.targetCompany = data.customerId;
                this.form.customerName = data.customerName;
                this.$refs.form.validateField('customerName', (valid) => {});
                this.curPage= 1;
                this.isNoMore = false;
                this.jobLevelOptions = [];
                if(this.form.targetCompany) {
                    this.getJobLevelList(this.form.targetCompany);
                }
                this.$refs.departmentSelect.reset();
                this.form.customerDepartmentId = '';
                this.form.customerDepartmentName = '';
                if(this.$refs.projectSelect) {
                    this.$refs.projectSelect.reset();
                    this.form.projectId = '';
                }
            },
            selectHotCity(name, code) {
                if(this.form.locations.length == 5) {
                    shortTips('最多选择五项地区！');
                    return false;
                }
                if(this.form.locations.indexOf(Number(code)) > -1) {
                    return false;
                }
                this.form.locations.push(code);
                switch(name) {
                    case '北':
                        name = '北京';
                        break;
                    case '上':
                        name = '上海';
                        break;
                    case '广': 
                        name = '广州';
                        break;
                    case '深':
                        name = '深圳';
                        break;
                    default: 
                        break;
                }

                if(this.locationName) {
                    this.locationName = `${this.locationName},${name}`;
                }else {
                    this.locationName = name;
                }
            },
            showCitySelect() {
                this.$refs.selectCityDialog.show();
                this.$nextTick(() => {
                    if(this.$refs.selectCityDialog.$refs.provinceCity) {
                        this.$refs.selectCityDialog.$refs.provinceCity.clearSelected();
                        this.$refs.selectCityDialog.$refs.provinceCity.setCheckedCityText(this.form.locations);
                        // this.form.locations.forEach(code => {
                        //     this.$refs.selectCityDialog.$refs.provinceCity.selectCityOutside(code);
                        // })
                    }
                })
            },
            handleCitySelect() {
                let selected = this.$refs.selectCityDialog.selectedCity;
                let target = [];
                let targetNames = [];
                selected.forEach(item => {
                    target.push(item.id);
                    targetNames.push(item.text);
                })
                this.form.locations = target;
                this.locationName = targetNames.join(',');
            },
            handleJobLevelItem(item,index){
                let _index = -1;
                this.jobLevelSelectedArr.forEach((selItem,selIndex)=>{
                    if(selItem.id == item.id){
                        item.isSelected = false;
                        this.jobLevelOptions[index] = item;
                        _index = selIndex;
                    }
                })
                if(_index>-1){
                    this.jobLevelSelectedArr.splice(_index,1);
                }else{
                    this.jobLevelSelectedArr.push(item);
                    this.jobLevelOptions[index].isSelected = true;
                }
                let jobLevel = '';
                this.jobLevelSelectedArr.forEach(selItem=>{
                    jobLevel += selItem.name + '/';
                })
                this.jobLevel = jobLevel.substring(0,jobLevel.length-1);
                let salaryRange = this.getSalaryRange(this.jobLevelSelectedArr);
                this.form.minSalary = salaryRange.min;
                this.form.maxSalary = salaryRange.max;
                //this.showJobLevelList = false;
            },
            setJobLevelSelect(dataArr){
                dataArr.forEach((item)=>{
                    item.isSelected = false;
                })
                return dataArr;
            },
            handleInputJobLevel(){
                this.showJobLevelList  = false;
                this.hasInputJobLevel = true;
                if(!this.jobLevel && this.jobLevelOptions.length>0){
                    this.jobLevelSelectedArr = [];
                    this.showJobLevelList = true;
                    this.jobLevel = '';
                    this.jobLevelOptions = this.setJobLevelSelect(this.jobLevelOptions);
                }else if(!this.jobLevel){
                    this.hasInputJobLevel = false;
                }
            },
            getJobLevelList(customerId){
                let url = firmUrl.url_customer_job_level.replace(/\%p/ig,customerId);
                this.isLoading = true;
                _request({
                    url: url,
                    method: 'GET',
                    data: {start:(this.curPage-1)*this.pageSize,take:this.pageSize}
                }).then(res => {
                    this.jobLevelOptions = this.jobLevelOptions.concat(this.setJobLevelSelect(res.list));
                    this.isLoading = false;
                    if(res.list.length<this.pageSize){
                        this.isNoMore = true;
                    }else{
                        this.curPage ++;
                    }
                })
            },
            getSalaryRange(arr){
                let salaryArr = [];
                arr.forEach(item=>{
                    salaryArr.push(item.maxSalary);
                    salaryArr.push(item.minSalary);
                })
                console.log(salaryArr);
                if(salaryArr.length==0){
                    return {
                        min: 0,
                        max: 0
                    }
                }else{
                    return {
                        min: Math.min.apply(Math,salaryArr),
                        max: Math.max.apply(Math,salaryArr)
                    }
                }

            },
            handleFocusJobLevel(){
                if(!this.hasInputJobLevel && this.jobLevelOptions.length>0){
                    this.showJobLevelList = true;
                }
                if(!this.jobLevel){
                    this.hasInputJobLevel = false;
                    this.showJobLevelList = true;
                }
            },
            handleScrollList(e){
                let loadArea = e.target;
                let scrollHeight = loadArea.scrollHeight;//文档高度
                let offsetHeight = loadArea.offsetHeight;//可视区域高度
                let scrollTop = loadArea.scrollTop;//滚动的高度
                if (scrollTop + offsetHeight >= scrollHeight && !this.isNoMore && !this.isLoading) {
                    console.log('到底了');
                    this.getJobLevelList(this.form.targetCompany);
                }
            },
            handleClose(done) {
                this.dialogVisible = false;
            },
            editCustomerGrade(isEdit, json) {
                this.$refs.cusGradeEditDialog.show(isEdit, json);
            },
            gotoJobManage(){
                if(!this.form.targetCompany){
                    shortTips('请选择所属公司');
                    return false;
                }
                this.getTopJobLevelId(this.form.targetCompany);
            },
            getTopJobLevelId(id){
                let url = firmUrl.url_job_level_id.replace(/\%p/ig,id);
                _request({
                    url: url,
                    method: 'GET'
                }).then((res) => {
                    // window.open(`/Headhunting/MyCompany.html#/Customer/${res}?tabs=grade`);
                    // window.open(`/#/Customer/${res}?tabs=grade`);

                    this.dialogVisible = true;
                    this.getGradeList();
                })
            },
            getGradeList() {
                this.listLoading = true;
                _request({
                    url: '/openapi/Customer/%p/Grades'.replace(/%p/g, this.form.targetCompany),
                    method: 'GET',
                    baseURL: "LbdOpenApi",
                }).then((res) => {
                    this.dataList = res;
                    this.jobLevelOptions = res;
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.listLoading = false;
                })
            },
            getQueryString(name) {
                let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
                if(window.location.hash.indexOf("?") < 0){
                        return null;
                }
                let r = window.location.hash.split("?")[1].match(reg); 
                if (r != null) return decodeURIComponent(r[2]);
                return null;
            },
            getJobData(jobId){
                
                let params = {};
                if(jobId) {
                    // let api = '/Headhunting/Job/%p/GetEditingJobInfo';
                    let api = '/openapi/Job/%p/Detail';
                    let url = api.replace(/\%p/ig, jobId);
                    params = {
                        url: url,
                        method: 'GET',
                        baseURL: "LbdOpenApi",
                    };
                }
                if(this.getQueryString('applicationId')) {
                    params = {
                        url: jobUrl.get_job_apply_detail.replace(/\%p/ig, this.getQueryString('applicationId')),
                        method: 'GET',
                        baseURL: "LbdOpenApi",
                    };
                }

                this.loading = true;

                _request(params)
                .then(res => {
                    let data = res;
                    let form = this.form;
                    form.name = data.name;
                    form.category = data.category || data.categoryId;
                    form.customerName = data.customerName;
                    form.targetCompany = data.customerId;
                    if(data.location && !data.locations) {
                        form.locations.push(data.location);
                    } else if(data.locations && data.locations.length) {
                        form.locations = data.locations;
                    }
                    if(this.getQueryString('applicationId')) {
                        // form.locations = [String(data.locationId)];
                        form.locations = data.locations.map(item => String(item));
                        this.locationName = data.location;
                    }
                    form.address = data.address;
                    form.minSalary = data.minSalary;
                    form.maxSalary = data.maxSalary;
                    form.minYearOfExperience  = String(data.minYearOfExperience);
                    form.minDegree = String(data.minDegree);
                    form.recruitingCount = data.recruitingCount;
                    form.description = data.description;
                    form.analysis = data.analysis;
                    form.emergencyDegree = data.emergencyDegree;
                    form.difficultDegree = data.difficultDegree;
                    form.guaranteePeriod = data.guaranteePeriod;
                    form.commissionType = String(data.commissionType);
                    form.commissionValue = this.getQueryString('jobId') ? data.commissionValue :data.commissionTypeValue;
                    form.IsPublic = this.isPublic = data.isPublic;
                    if(data.isPublic) {
                        form.PublicCommissionType = String(data.publicCommissionType);
                        form.PublicCommissionValue = data.publicCommissionValue;
                        form.HeadhuntingFirmCommissionValue = data.headhuntingFirmCommissionValue;
                        form.OtherFirmCommissionValue = data.otherFirmCommissionValue;
                        form.PublicGuaranteePeriod = data.publicGuaranteePeriod;
                        form.RefundPolicyDescription = data.refundPolicyDescription;
                        if(data?.visibleFirmIdList && data?.visibleFirmIdList[0] === '00000000-0000-0000-0000-000000000000') {
                            this.visibleFirmIdList = [0];
                        } else {
                            this.visibleFirmIdList = data.visibleFirmIdList;
                        }
                        form.AnonymousFirmName = data.anonymousFirmName;
                    }

                    this.jobLevel = data.expectedRank;

                    this.$refs.customerSelect.customerId = data.customerId;
                    this.handleCustomerNodeClick({
                        customerName: data.customerName,
                        customerId: data.customerId
                    })

                    this.$refs.departmentSelect.value = data.customerDepartmentId;
                    this.$refs.departmentSelect.editInit({
                        id: data.customerDepartmentId
                    });

                    if(this.form.locations.length > 0) {
                        let cityTextArr = [];
                        this.form.locations.forEach(code => {
                            cityTextArr.push(getCityTextById(code));
                        })
                        this.locationName = cityTextArr.join(',');
                    }

                    this.loading = false;
                })
            },
            cancelEdit() {
                if(this.isEdit) {
                    // location.href = `/Headhunting/MyCompany.html#/Job/${this.jobId}`;
                    location.href = `/Headhunting/#/Job/${this.jobId}`;
                } else {
                    // location.href = `/Headhunting/MyCompany.html#/jobList`;
                    location.href = `/Headhunting/#/jobList`;
                }
            },
            handleBlurJobLevel(e) {
                if(e.srcElement.value && e.srcElement.value.length > 20) {
                    this.jobLevelError = true;
                } else {
                    this.jobLevelError = false;
                }
            },
            getFirmCommissionValue() {
                _request({
                    url: companyUrl.commission_standard,
                    method: "GET"
                }).then((res) => {
                    if(!this.isPublic) {
                        this.headhuntingFirmCommissionValue = this.form.HeadhuntingFirmCommissionValue = res.headhuntingFirmCommissionValue;
                        this.otherFirmCommissionValue = this.form.OtherFirmCommissionValue = res.otherFirmCommissionValue;
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            validateHFCommissionValue(rule, value, callback) {
                if(!this.isPublic) {
                    if(value > 100 || value < 0) {
                        callback(new Error("比例值的范围是0~100！"));
                    } else if(value > this.headhuntingFirmCommissionValue + 30 || value < this.headhuntingFirmCommissionValue - 30) {
                        callback(new Error("分佣比例不能超过基准佣金上下30%！"))
                    } else {
                        callback();
                    }
                } else {
                    callback();
                }
            },
            validateOFCommissionValue(rule, value, callback) {
                if(!this.isPublic) {
                    if(value > 100 || value < 0) {
                        callback(new Error("比例值的范围是0~100！"));
                    } else if(value > this.otherFirmCommissionValue + 30 || value < this.otherFirmCommissionValue - 30) {
                        callback(new Error("分佣比例不能超过基准佣金上下30%！"))
                    } else {
                        callback();
                    }
                } else {
                    callback();
                }
            },
            handleCategoryChange(value) {
                this.form.category = value;
            },
            updateDepartmentId(department) {
                this.form.customerDepartmentId = department.id;
                this.form.customerDepartmentName = department.name.slice(department.name.lastIndexOf('/') + 1);

                if(this.$refs.projectSelect) {
                    this.$refs.projectSelect.reset();
                    this.form.projectId = '';
                    if(this.form.targetCompany && this.form.customerDepartmentId) {
                        this.$refs.projectSelect.getProjectList({
                            customerId: this.form.targetCompany,
                            customerDepartmentId: this.form.customerDepartmentId
                        });
                    }
                }
            },
             refreshPorjectList() {
                if(this.form.targetCompany && this.form.customerDepartmentId) {
                    this.$refs.projectSelect.getProjectList({
                        customerId: this.form.targetCompany,
                        customerDepartmentId: this.form.customerDepartmentId
                    });
                }
            },
            changeProject(project) {
                this.form.projectId = project.projectId;
                this.$refs.form.validateField('projectId', (valid) => {});
            },
            // Validate
            validateCommissionValue(rule, value, callback) {
                if(isNaN(+value)) {
                    callback(new Error(`请输入合法的比例!`));
                } else {
                    if(+value >= 100 || +value<0) {
                        callback(new Error(`比例范围在0-100之间!`));
                    }
                    if(value && String(value).match(/\.\d*/g)?.[0]?.length>3) {
                        callback(new Error(`最多支持两位小数!`));
                    }
                    callback();
                }
            },
        }
    }
</script>

<style lang="scss">
    @import '#/css/scss/mixins/_clearfix.scss';
    .job-create {
        height: 100%;
        overflow-y: auto;
        margin: 20px 20px 30px;
        padding-bottom: 20px;

        .grade-salary-dialog {
            .el-dialog__body {
                margin-bottom: 20px;
                max-height: 500px;
                overflow-y: auto;
            }
        }
        
        .single-job-create {
            // width: 1200px;
            // margin: 0 auto;
            background-color: #ffffff;
            border-radius: 8px;
            padding-bottom: 20px;
            .publish-error{
                color: #ff7200;
                margin-left: 5px;
            }

            &-top {
                height: 60px;
                border-bottom: 1px solid #EEEEEE;
            }

            &-title {
                float: left;
                padding-left: 25px;
                // margin-top: 10px;
                margin-bottom: 0;
                line-height: 60px;
                font-size: 18px;
            }

            &-publish-title {
                margin-left: -25px;
                margin-bottom: 25px;
                .el-checkbox{
                    margin-right:10px;
                    .el-checkbox__inner{
                        width: 20px;
                        height: 20px;
                        &:hover{
                            border-color:#3399ff;
                        }
                    }
                    .el-checkbox__label{
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #fff;
                        border-color: #3399ff;
                    }
                    .el-checkbox__input.is-checked + .el-checkbox__label {
                        color: #3399ff;
                    }
                    .el-checkbox__input.is-checked .el-checkbox__inner::after{
                        height: 11px;
                        left: 6px;
                        width: 4px;    
                        border-color: #3399ff;
                        border-width: 2px;
                    }
                    .el-checkbox__input.is-focus .el-checkbox__inner{
                        border-color: #3399ff;
                    }
                }
                .el-button.publish-A2A{
                    width: 35px;
                    height: 20px;
                    padding: 0;
                    min-width: 0;
                    font-size: 12px;
                    line-height: 20px;
                    border-radius: 18px;
                    background-color: #3399ff;
                    border-color: #3399ff;
                    color: #fff;
                }
            }

            &-tip {
                float: left;
                height: 60px;
                line-height: 60px;
                margin-left: 42px;
                // margin-bottom: 20px;
                // padding: 10px 20px;
                color: #666;
                // background-color: #f5fbfa;
                // border: 1px solid #eef2f5;

                > i {
                    font-size: 16px;
                    color: #ccc;
                    // margin-right: 16px;
                    margin-right: 10px;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            &-form {
                // padding: 0 25px;
                padding: 0 50px;
                .col-16 {
                    width: 800px !important;
                }
                .col-8 {
                    width: 400px !important;
                }
                .col-4 {
                    width: 200px !important;
                }

                &-title {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 16px;
                    margin: 25px 0;
                    position: relative;

                    &:before, &:after {
                        content: '';
                        position: absolute;
                    }

                    &:before {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        left: -27px;
                        top: 0;
                        z-index: 1;
                        background-color: #3399ff;
                    }

                    &:after {
                        width: 2px;
                        height: 130px;
                        background-color: #f3f3f3;
                        left: -23px;
                        top: 10px;
                    }
                }

                label {
                    text-align: left;
                    margin-bottom: 0;
                }

                .hot-city {
                    @include clearfix();

                    span {
                        display: inline-block;
                        padding: 0 4px;
                        color: $primary;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }

                .add-customer-btn, .add-department-btn {
                    display: inline-block;
                    height: 40px;
                    margin-left: 15px;
                    font-size: 15px;
                    color: $primary;
                    cursor: pointer;

                    > i {
                        font-size: 22px;
                        margin-right: 6px;
                        line-height: 40px;
                    }

                    > em {
                        vertical-align: top;
                    }
                }

                &-btn {
                    margin-top: 30px;
                    width: 150px;
                }
            }
        }
        .lbd-sign {
            margin: 20px 0;
            &:after {
                content: '© 2024 liebide.com';
                margin-left: 50%;
                text-align: center;
                font-size: 14px;
                color: #999;
            }
        }
    }

    .job-categories {
        &-title {
            margin: 6px 0;
            padding-left: 10px;
        }
        .el-select-dropdown__item {
            padding-left: 40px;
        }
    }
    .job-customers-tree {
        height: 260px;
        overflow-y: scroll;

        .el-tree-node__expand-icon {
            display: none;
        }
    }
    .job-level-wrap{
        position: relative;
        font-size: 14px;
        color: #444;
        padding: 0 6px;
        .job-level-ipt{
            width: 390px;
            line-height: 42px;
            border-radius: 4px;
            border: 1px solid #DCDFE6;
            padding: 0 15px;
            &:focus{
                border: 1px solid #38BC9D;
            }
        }
        .job-manage-link{
            color: #38BC9D;
            font-size: 14px;
            cursor: pointer;
            .icon-job-manage{
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url('../../assets/images/icon_manage.png') center no-repeat;
                background-size: cover;
                vertical-align: middle;
                margin-right: 6px;
            }
        }
        .job-level-list{
            position: absolute;
            width: 400px;
            background-color: #fff;
            border: 1px solid #ebebeb;
            box-shadow: 0 0 10px rgba(0,0,0,.1);
            top: 50px;
            left: 0;
            z-index: 6;
            border-radius: 2px;
            padding: 10px 0;
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: auto;
            .job-level-item{
                height: 40px;
                line-height: 40px;
                position: relative;
                padding: 0 30px;
                cursor: pointer;
                &:hover{
                    color: #38BC9D;
                    background: rgba(56,188,157,.1);
                }
                &.selected{
                    color: #38BC9D;
                    &:after{
                        font-family: 'iconfont';
                        display: block;
                        position: absolute;
                        content: '\e64f';
                        top:0;
                        right: 20px;
                        font-size: 12px;
                    }
                }
            }
        }


    }
    .item-more-btn{
        position: relative;
        display: inline-block;
        padding: 0 20px;
        font-size: 14px;
        color: #38BC9D;
        .icon-new{
            font-size: 18px;
        }
        &.help-btn{
            cursor: pointer;
            &:hover{
                .tip-wrap{
                    display: block;
                }
            }

        }
    }
    .tip-wrap{
        display: none;
        position: absolute;
        left: 24px;
        width: 260px;
        padding: 10px;
        font-size: 12px;
        color:#666;
        line-height: 1.6;
        background: #FFFFFF;
        box-shadow: 0 0 12px 0 rgba(0,0,0,0.20);
        border-radius: 4px;
    }

    .input-commission-value {
        .el-input__inner {
            text-align: right;
            &:nth-of-type(1) {
                padding-left: 53px;
            }
            &:nth-of-type(2) {
                padding-left: 57px;
            }
        }
        .el-input__prefix {
            padding: 0 10px 0 15px;
            color: #999;
        }
        &.is-disabled {
            .el-input__prefix {
                color: #C0C4CC;
            }
        }
    }

    .input-visible-firm.el-select {
        .el-tag.el-tag--info {
            font-size: 14px;
            height: 18px;
            line-height: 18px;
            background-color: #fff;
            border: none;
            color: #666;
            padding: 0;
            &:nth-of-type(1) {
                padding-left: 8px;
            }
            &:nth-of-type(n+2) {
                &::before {
                    content: ",";
                    margin-right: 6px;
                }
            }
            .el-tag__close {
                display: none;
            }
        }
    }
</style>

<style lang="scss">
.customer-select.el-select-dropdown {
    min-width: 390px !important;
    max-width: 390px;
}
.department-select.el-select-dropdown {
    min-width: 390px !important;
    max-width: 390px;
}
</style>
